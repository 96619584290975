import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { FaFacebook, FaInstagram, FaApple, FaGooglePlay } from "react-icons/fa"; // Use other icons as needed
import { SiX } from "react-icons/si"; // Use SiX for the X logo from simple-icons package

const Footer = () => {
  return (
    <footer className="bg-black border-t border-gray-700 text-gray-400">
      <div className="container mx-auto px-4 py-8">
        {/* Footer Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* About Section */}
          <div>
            <h3 className="text-lg font-semibold text-white mb-4">About Quiqi</h3>
            <p className="text-sm">
              Quiqi brings you the latest news in a concise format, helping you stay informed in just 60-80 words. Explore news categories and enjoy a streamlined reading experience.
            </p>
          </div>

          {/* Links Section */}
          <div>
            <h3 className="text-lg font-semibold text-white mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/contact-us" className="hover:text-white transition-colors">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="hover:text-white transition-colors">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service" className="hover:text-white transition-colors">
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>

          {/* Social Media and App Store Links */}
          <div>
            <h3 className="text-lg font-semibold text-white mb-4">Follow & Download</h3>
            {/* Social Media Links */}
            <div className="flex items-center space-x-4 mb-4">
            <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-white transition-colors"
              >
                <SiX className="text-2xl" /> {/* Updated for X */}
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-white transition-colors"
              >
                <FaInstagram className="text-2xl" />
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-white transition-colors"
              >
                <FaFacebook className="text-2xl" />
              </a>
              
              
            </div>

            {/* App Store and Google Play Links */}
            <ul className="space-y-4">
              <li>
                <a
                  href="https://apps.apple.com" // Replace with the actual Apple App Store link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 hover:text-white transition-colors"
                >
                  <FaApple className="text-xl" />
                  Download on the App Store
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store" // Replace with the actual Google Play Store link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 hover:text-white transition-colors"
                >
                  <FaGooglePlay className="text-xl" />
                  Get it on Google Play
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-8 text-center border-t border-gray-700 pt-4">
          <p className="text-sm">
            &copy; {new Date().getFullYear()} Quiqi. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
