import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { TrendingUp } from "lucide-react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ContactUs from "./pages/Contactus";
import PrivacyPolicy from "./pages/Privacy-policy";
import TermsOfService from "./pages/Termsofservice";
import Readnews from "./pages/Readnews";
import './App.css';

const Spotlight = ({ mousePosition }) => (
  <div
    className="pointer-events-none fixed inset-0 z-10 transition-opacity duration-300"
    style={{
      background: `radial-gradient(600px circle at ${mousePosition.x}px ${mousePosition.y}px, rgba(168, 85, 247, 0.15), transparent 40%)`,
    }}
  />
);

const StatCard = ({ number, label, trend }) => (
  <div className="relative group cursor-pointer">
    <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-lg blur-xl transition-opacity opacity-0 group-hover:opacity-100" />
    <div className="relative p-4 bg-white/5 backdrop-blur-sm rounded-lg border border-white/10">
      <h3 className="text-2xl font-bold bg-gradient-to-r from-white to-purple-200 bg-clip-text text-transparent mb-1">
        {number.toLocaleString()}+
      </h3>
      <p className="text-xs text-gray-400">{label}</p>
      {trend && (
        <span className="absolute top-2 right-2 text-green-400 text-xs">
          +{trend}%
        </span>
      )}
    </div>
  </div>
);



const Carousel = ({ news }) => {
  const scrollRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const items = [...news, ...news, ...news, ...news]; // Added more duplicates for smoother transition

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let animationFrameId;
    
    const animate = () => {
      if (!isHovered && scrollContainer) {
        // Increment scroll by 1 pixel each frame
        if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth - scrollContainer.clientWidth) {
          scrollContainer.scrollLeft = 0;
        } else {
          scrollContainer.scrollLeft += 1; // Adjust this number to control speed
        }
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    // Start animation immediately
    animate();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [isHovered]);

  return (
    <div 
      className="carousel-container overflow-hidden"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div 
        className="scroll-container"
        ref={scrollRef}
        style={{
          display: 'flex',
          gap: '1rem',
          willChange: 'scroll-position',
          scrollBehavior: 'auto'
        }}
      >
        {items.map((item, index) => (
          <div 
            key={`${item.title}-${index}`} 
            className="card-container"
            style={{
              flex: '0 0 auto',
              width: 'calc(100% - 2rem)',
              maxWidth: '300px',
              transform: 'translateZ(0)',
            }}
          >
            <div className="card">
              <div className="relative aspect-[16/9] overflow-hidden">
                <img
                  src={item.image || "/api/placeholder/300/180"}
                  alt={item.title}
                  className="w-full h-full object-cover"
                  loading="lazy"
                />
                {item.trending && (
                  <div className="absolute top-2 right-2 bg-purple-500 text-white px-2 py-1 rounded-full text-xs flex items-center gap-1">
                    <TrendingUp className="w-3 h-3" />
                    #{item.trending}
                  </div>
                )}
              </div>
              <div className="p-4">
                <h3 className="text-sm md:text-base font-bold text-white mb-2 line-clamp-2">
                  {item.title}
                </h3>
                <p className="text-xs md:text-sm text-gray-300 line-clamp-3">
                  {item.overview}
                </p>
                <div className="mt-4 flex items-center justify-between text-xs text-gray-400">
                  <span className="flex items-center gap-1">
                    <TrendingUp className="w-3 h-3" />
                    {item.views.toLocaleString()}
                  </span>
                  <span>{item.timestamp}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};;

const HomePage = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const trendingNews = [
    {
      title: "SpaceX Successfully Launches Starship Test Flight",
      category: "Space",
      views: 12340,
      trending: 1,
      timestamp: "2h ago",
      image: "/api/placeholder/300/180",
      overview: "SpaceX conducted a historic test flight of Starship, marking a new era in space exploration."
    },
    {
      title: "New AI Model Breaks Performance Records",
      category: "Technology",
      views: 11250,
      trending: 2,
      timestamp: "3h ago",
      image: "/api/placeholder/300/180",
      overview: "A groundbreaking AI model has surpassed previous benchmarks, revolutionizing the tech industry."
    },
    {
      title: "Breakthrough in Cancer Treatment Research",
      category: "Health",
      views: 7340,
      trending: 3,
      timestamp: "6h ago",
      image: "/api/placeholder/300/180",
      overview: "Scientists discover promising new approach to cancer treatment using targeted therapy."
    },
    {
      title: "Global Climate Summit Reaches Historic Agreement",
      category: "Environment",
      views: 6820,
      trending: 4,
      timestamp: "8h ago",
      image: "/api/placeholder/300/180",
      overview: "World leaders commit to ambitious climate goals in landmark agreement."
    },
    {
      title: "Revolutionary Battery Technology Unveiled",
      category: "Technology",
      views: 5930,
      trending: 5,
      timestamp: "10h ago",
      image: "/api/placeholder/300/180",
      overview: "New battery design promises longer life and faster charging for electric vehicles."
    },
  ];

  useEffect(() => {
    const handleMouseMove = (e) => {
      requestAnimationFrame(() => {
        setMousePosition({ x: e.clientX, y: e.clientY });
      });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <Spotlight mousePosition={mousePosition} />
      <Navbar />
      <section className="pt-24 pb-12 px-4 text-center">
        <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-br from-white via-purple-200 to-pink-200 bg-clip-text text-transparent">
          News in a Nutshell
        </h1>
        <p className="text-sm md:text-base text-gray-400 mb-8 max-w-xl mx-auto">
          Experience news differently. Clear, concise, and comprehensive in 60 words.
        </p>
      </section>
      <section className="pb-16" id="news">
        <div className="container mx-auto px-4">
          <h2 className="text-lg font-bold flex items-center gap-2 mb-4">
            <TrendingUp className="w-4 h-4 text-purple-400" />
            Trending Stories
          </h2>
        </div>
        <Carousel news={trendingNews} />
      </section>
      <section className="container mx-auto px-4 pb-16">
        <h2 className="text-lg font-bold mb-6">Our Impact</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <StatCard number={50000} label="Daily Readers" trend={12} />
          <StatCard number={1000} label="Stories Published" trend={8} />
          <StatCard number={98} label="Satisfaction %" trend={5} />
          <StatCard number={24} label="Updates/Hour" trend={15} />
        </div>
      </section>
      <Footer />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Home Route */}
        <Route path="/" element={<HomePage />} />

        {/* Other Pages */}
        <Route path="/Readnews" element={<Readnews />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </Router>
  );
};

export default App;

