import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.svg";

const Navbar = () => (
  <nav className="fixed w-full z-50 bg-black/80 backdrop-blur-xl border-b border-white/5">
    <div className="container mx-auto px-4 py-3 flex justify-between items-center">
      <div className="flex items-center gap-2">
        <img src={logo} alt="Quiqi Logo" className="w-8 h-8 md:w-12 md:h-12" />
      </div>
      <div>
        {/* Use Link to navigate to Read News */}
        <Link
          to="/readnews" // Redirect to the Read News page
          className="py-2 px-4 rounded-lg shadow-md font-semibold text-white text-sm md:text-base transition-all duration-200"
          style={{
            background: "linear-gradient(90deg, #A855F7 0%, #D946EF 50%, #EC4899 100%)",
            boxShadow: "0 4px 15px rgba(236, 72, 153, 0.4)",
          }}
        >
          Read News
        </Link>
      </div>
    </div>
  </nav>
);

export default Navbar;
